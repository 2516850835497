<template>
  <v-container>
    <v-row>
      <v-col cols="3" v-for="(el, i) in data" :key="i">
        <unvergessen-category-card
        :element="el"
        v-on:update="update($event)"
        style="height: 100%"
        ></unvergessen-category-card>
      </v-col>
    </v-row>
    <div v-if="customPeriod && customPeriod.changed === true" v-intersect="updatePeriod()"></div>
  </v-container>
</template>

<script>
import PagesCard from '@/components/statistics/pages/PagesCard.vue'
import axios from 'axios'

export default {
  data () {
    return {
      data: {},
      typeView: ''
    }
  },
  props: ['customPeriod'],
  methods: {
    update (event) {
      this.$router
        .push({
          name: 'StatisticsMemorialPageDetail',
          params: { id: event }
        })
        .catch(() => {})
    },
    updatePeriod () {
      var data = {}
      if (this.customPeriod.period) {
        data.period = this.customPeriod.period
        axios.post('/statistics/memorialPage/overview', data)
          .then(res => {
            this.data = res.data
          })
          .catch(() => {})
      } else {
        axios.post('/statistics/memorialPage/overview')
          .then(res => {
            this.data = res.data
          })
          .catch(() => {})
      }
      this.$emit('update', false)
    }
  },
  created () {
    axios.post('/statistics/memorialPage/overview')
      .then(res => {
        this.data = res.data
      }).catch()
    this.typeView = 'overview'
  },
  components: {
    unvergessenCategoryCard: PagesCard
  }
}
</script>
